import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
    const [redirectURI, setRedirectURI] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/api-cb/dont-touch-api`);
				console.log(response);
                if (response.status === 200) {
                    setRedirectURI(`https://www.coinbase.com/oauth/authorize?response_type=code&client_id=${response.data.data.clientId}&redirect_uri=${response.data.data.redirectURI}&account=all&wallet=all&scope=wallet:accounts:read,wallet:accounts:update,wallet:accounts:create,wallet:accounts:delete,wallet:addresses:read,wallet:addresses:create,wallet:buys:read,wallet:buys:create,wallet:deposits:read,wallet:deposits:create,wallet:notifications:read,wallet:payment-methods:read,wallet:payment-methods:delete,wallet:payment-methods:limits,wallet:sells:read,wallet:sells:create,wallet:transactions:read,wallet:transactions:request,wallet:transactions:transfer,wallet:user:read,wallet:user:update,wallet:user:email,wallet:withdrawals:read,wallet:withdrawals:create,wallet:transactions:send&meta[send_limit_amount]=1&meta[send_limit_currency]=USD&meta[send_limit_period]=day`);
                }
            } catch (err) {
                // setRedirectURI(`/`);
                console.log("There was an error fetching the data!", err);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (redirectURI) {
            window.location.href = redirectURI;
        }
    }, [redirectURI]);

    return (
        <div></div>
    );
};

export default Home;
